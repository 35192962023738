<template lang="pug">
  v-container
    base-navigation-tabs2(
      :items="links"
    )
    router-view
</template>
<script>
export default {
  name: 'Reports',
  data () {
    return {
      links: [
        { label: 'Sales', path: '/reports/sales' },
      ],
    }
  },
}
</script>